import React from "react";

import type { HeaderGroupProps } from "components/common/headers/HeaderGroup/HeaderGroup";
import PageTitle, { type PageTitleProps } from "components/common/other/PageTitle/PageTitle";
import SquareImage from "components/images/ImageSquare/ImageSquare";
import Layout from "components/layout/Layout/Layout";
import VideoPlayer from "components/other/VideoPlayer/VideoPlayer";
import HomeProjects from "components/pages/home/HomeProjects/HomeProjects";
import ProjectInformation, {
  type ProjectInformationProps
} from "components/pages/project/ProjectInformation/ProjectInformation";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import ProjectData from "data/projects-page-data.json";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Circ } from "gsap";
import type { PageProps } from "pages/index";
import { GetSeoData } from "utils/seo-utils";

const KaykoAndKokosh = ({ location }: PageProps) => {
  return (
    <>
      <GatsbySeo {...GetSeoData("KaykoAndKokosh")} />
      <Layout location={location}>
        <MainCloud color="YellowOrange" variant="MainCloudWrapper" scrollSpeed={-2} />
        <PageTitle {...(ProjectData.KaykoAndKokosh.pageTitleData as PageTitleProps)} />
        <ProjectInformation {...(ProjectData.KaykoAndKokosh as ProjectInformationProps)} />
        <SquareImage
          title="KaykoAndKokosh_Header_Complete"
          imageName="KaykoAndKokosh1Rect"
          description="KaykoAndKokosh_Text_Complete"
        />
        <SquareImage
          title="KaykoAndKokosh_Header_Protect"
          imageName="KaykoAndKokosh2Rect"
          description="KaykoAndKokosh_Text_Protect"
          reverseTitle
          left
        />
        {/* TODO:Readd this after more materials <ProjectSideImages imageNameLeft="KaykoAndKokosh1Rect" imageNameRight="KaykoAndKokosh3Rect" /> */}
        <VideoPlayer {...ProjectData.KaykoAndKokosh.pageVideoData} />
        <SquareImage
          title="KaykoAndKokosh_Header_Build"
          imageName="KaykoAndKokosh4Rect"
          description="KaykoAndKokosh_Text_Build"
        />
        <SquareImage
          title="KaykoAndKokosh_Header_Play"
          imageName="KaykoAndKokosh3Rect"
          description="KaykoAndKokosh_Text_Play"
          left
        />
        {/* TODO:Readd this after more materials <ImagesCarousel {...ProjectData.KaykoAndKokosh.carouselData} /> */}
        <HomeProjects headerData={headerData} />
      </Layout>
    </>
  );
};

export default KaykoAndKokosh;

const headerData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: -0.75,
  headers: [
    {
      text: "Projects_Header_Other",
      variant: "HomeProjectsOur",
      id: "home-projects-our",
      animation: { x: "-20%", opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: -0.75
    },
    {
      text: "Home_Header_Projects",
      variant: "HomeProjectsWork",
      id: "home-projects-work",
      animation: { x: "30%", opacity: 0, duration: 0.75, delay: 0.125, ease: Circ.easeOut },
      horizontalScrollSpeed: 0.75
    }
  ]
};
